/*--------------------------------------------------------------
# Style
--------------------------------------------------------------*/
.admin_dashboard {
	.logo {
		width: 150px;
		float: left;
		margin-bottom: 20px;

		img {
			max-width: 100%;
		}
	}
	.items {
		width: 100%;
		float: left;

		.item {
			box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.1);
			width: 100%;
			float: left;
			padding: 10px;
			border-radius: 5px;
			margin-bottom: 10px;

			.status {
				width: 25px;
				height: 25px;
				border-radius: 30px;
				background: #eee;
				float: left;
				margin:3px 10px -3px 0px;

				&.aangemaakt {
					background: #f00;
					box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.1);
				}

				&.ingevuld {
					background: #e7851b;
				}

				&.afgerond {
					background: #3bb615;
				}
			}

			.title {
				float: left;
				font-weight: bold;
				line-height: 30px;
			}

			.date {
				float: right;
				line-height: 30px;
			}

			.bekijk {
				float: right;
				font-size: 14px;
				padding: 3px 15px;
				margin-left: 10px;
			}
		}
	}

	.button.generate {
		float: right;
		margin-top: 20px;
	}

	.page-right {
		float: left;
		font-weight: bold;
		margin-top: 10px;
	}

	.page-left {
		float: right;
		font-weight: bold;
		margin-top: 10px;
	}
}

.modal {
	width: 100%;
	float: left;
	position: fixed;
	background: rgba(0, 0, 0, 0.8);
	left: 0px;
	top: 0px;
	z-index: 1000;
	height: 100%;
	padding: 20px 0px;
	display: none;
	overflow-y: scroll; /* has to be scroll, not auto */
	-webkit-overflow-scrolling: touch;

	&.active {
		display: block;
	}

	p {
		padding: 0px 5px;
		width: 100%;
		float: left;
		margin-bottom: 0px;

		&.link {
			color: $color-primary;
		}
	}

	.inner {
		width: 450px;
		background: #fff;
		border-radius: 4px;
		left: 50%;
		position: relative;
		margin: 50px 0px 50px -225px;
		float: left;
		padding: 20px;
		box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.5);
	}

	.close {
		float: right;
		margin: -10px 0px;
		cursor: pointer;
		position: relative;
		z-index: 100;

		&:hover {
			opacity: 0.8;
		}
	}

	h3 {
		font-weight: bold;
		margin: 0px;
		width: 100%;
		float: left;
		padding: 0px 5px;
		font-size: 21px;
		margin-bottom: 10px;
	}

	.field {
		width: 100%;
		float: left;
		padding: 5px;
		margin-bottom: 5px;

		.label {
			font-weight: bold;
			font-size: 14px;

			sup {
				color:#f00;
			}
		}

		&.half {
			width: 50%;
		}

		input {
			width: 100%;
			float: left;
			border: 1px solid darken(#eee, 10);
			padding: 5px;
			border-radius: 3px;
			box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);

			&:focus {
				outline: none;
				border: 1px solid $color-primary;
			}
		}

		select {
			width: 100%;
			float: left;
			height: 37px;
		}
	}

	.button {
		width: 100%;
		border: 0px;
		border-radius: 3px;
	}
	

	&.url-modal {
		text-align: center;

		.fa.fa-check-circle {
			font-size: 40px;
			color: #3db115;
		}

		.link {
			color: $color-primary;
		}
	}
}

/*--------------------------------------------------------------
# Responsive
--------------------------------------------------------------*/
@include breakpoint(lg) {

}

@include breakpoint(md) {

}

@include breakpoint(sm) {

}