/*--------------------------------------------------------------
# Style
--------------------------------------------------------------*/
.top-bar {
	background: $color-text;
	float: left;
	width: 100%;
	padding: 10px 0px;
	z-index: 9999;
	position: fixed;
	top: 0px;

	.logo {
		font-size: 28px;
		color: $color-primary;
		font-weight: $bold;
		text-decoration: none !important;

		span {
			font-weight: $light;
			color: #fff;
		}
	}

	.container {
		width: 100%;
	}

	.wpml-ls-menu-item {
	    position: relative;
		float: right;

	    &.wpml-ls-current-language {
	        padding-right: 5px;
	        padding-top: 7px;

	        a:after {
	            font: normal normal normal 14px/1 FontAwesome;
	            content: "\f0d7";
	            width: 100%;
	            font-size: 18px;
	            top: -7px;
	            left: 10px;
	            position: relative;
	            color: #fff;
	            text-align: center;
	        }

	        a {
	        	padding-bottom: 0px;
	        	.wpml-ls-flag {
	        		float: left;
	        	}
	        }

	        .wpml-ls-native {
	            display: none;
	        }
	    }

	    .sub-menu {
	        display: none;
	        position: absolute;
	        background: #fff;
	        top: 38px;
	        width: 120px;
	        margin-left: -65px;
	        z-index: 100;
	        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
	        border-radius: 3px;
	        padding: 0px;

	        img {
	            float: left;
	        }

	        span {
	            float: left;
	            margin-top: -2px;
	        }

	        .wpml-ls-native {
	            display: block;
	        }

	        &:before {
	            font: normal normal normal 14px/1 FontAwesome;
	            content: "\f0d8";
	            width: 100%;
	            font-size: 30px;
	            position: absolute;
	            top: -20px;
	            left: 0px;
	            color: #fff;
	            padding-right: 23px;
	            text-align: right;
	            text-shadow: 0 -1px 1px rgba(0,0,0,.1);
	        }

	        li {
	            border-bottom: 1px solid #eee;
	            float: left;
	            width: 100%;
	            color: #000;

	            &:hover {
	                a {
	                    background: #eee;
	                }
	            }

	            .wpml-ls-flag {
					margin-top: 1px;
	            }

	            a {
	            	width: 100%;
	            	float: left;
	            	color: #000;
	                font-size: 12px;
	                padding: 10px 10px;

	                &:after {
	                	display: none;
	                }
	            }
	        }
	    }

	    &:hover {
	        .sub-menu {
	            display: block;
	        }
	    }
	}

	ul {
		list-style: none;
		float: right;
		padding: 0px;
		margin: 0px;

		li {
			display: inline-block;

			&.active {
				a {
					color: $color-primary;
				}
			}

			&.btn {
				a {
					background: $color-primary;
					color: #fff;
					border-radius: 5px;

					&:hover {
						background: darken($color-primary, 20);
						color: #fff;
					}
				}
			}

			&.sign-out {
				a {
					border-left: 1px solid rgba(255, 255, 255, 0.2);
					color: rgba(255, 255, 255, 0.6);
				}
			}

			a {
				color: #fff;
				display: block;
				padding: 9px 15px;
				text-decoration: none !important;

				&:hover {
					color: $color-primary;
				}
			}
		}
	}

	.menu-btn {
		background: none;
		float: right;
		color: #fff;
		border: 0px;
		font-size: 26px;
		margin-top: 1px;
		display: none;

		&:hover {
			opacity: 0.5;
		}

		&:focus {
			outline: none;
		}

		span {
			font-size: 14px;
			float: right;
			margin: 8px 0px 0px 5px;
		}
	}
}


/*--------------------------------------------------------------
# Responsive
--------------------------------------------------------------*/
@media (max-width: 700px) {
	.top-bar {
		.menu {
			display: none;
		}

		.menu-btn {
			display: block;
		}
	}
}


@include  breakpoint(xs) {

	.top-bar .logo {
		font-size: 24px;
	}

}

