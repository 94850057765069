body {
	font-family: $base-font-family;
	font-size: $base-font-size;
	line-height: $base-line-height;
	padding: 20px 0px 0px 0px;
	margin: 0px;

	&.admin-bar {
		//padding-top: 32px;

		.top-bar {
			top: 32px;
		}
	}
}

* {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;
}

.load-overlay {
	width: 100%;
	height: 100%;
	background: #fff;
	left: 0px;
	top: 0px;
	position: fixed;
	z-index: 999999;
}

.button {
	background: $color-primary;
	color: #fff;
	border-radius: 3px;
	padding: 7px 15px;
	font-weight: bold;
	font-size: 16px;
	box-shadow: 0px 3px 2px rgba(0, 0, 0, 0.1);
	cursor: pointer;

	&:hover {
		color: #fff;
		text-decoration: none;
		background: darken($color-primary, 5)
	}
}