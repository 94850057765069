/*--------------------------------------------------------------
# Style
--------------------------------------------------------------*/
.content {
	width: 100%;
	float: left;
	padding: 50px 0px;

	.page-title {
		font-size: 42px;
		font-weight: $bold;
		width: 100%;
		margin: 0px 0px 5px 0px;
		letter-spacing: -2px;
		float: left;
	}

	.page-sub {
		font-size: 21px;
		font-weight: $bold;
		color: $color-primary;

		.fa {
			font-size: 16px;
		}
	}

	h1, h2, h3, h4, h5, h6 {
		font-size: 32px;
		font-weight: $bold;
		letter-spacing: -2px;
		margin: 0px 0px 10px 0px;
	}

	h4, h5, h6 {
		font-size: 26px;
	}

	p {
		font-weight: $light;
		line-height: 2em;
		letter-spacing: -0.5px;
		text-align: justify;

		&.intro {
			font-weight: $regular;
		}
	}

	a {
		font-weight: $bold;
		color: $color-primary;
		text-decoration: underline;
	}

	.image-center {
		text-align: center;

		img {
			max-width: 100%;
			height: auto; 
			margin: auto;
		}
	}

	.block-full {
		width: 100%;
		float: left;
		margin: 0px 0px 20px 0px;
	}

	.block-image-left, .block-image-right {
		width: 100%;
		float: left;
		margin: 0px 0px 20px 0px;
	}

	.block-full_img {
		width: 100%;
		float: left;

		img {
			max-width: 100%;
			height: auto;
		}
	}
}

.full_image {
    max-width: 65%;
    height: auto;
    padding: 5px 0;
}

#um_account_submit {
  margin: 10px 0;
}

/*--------------------------------------------------------------
# Responsive
--------------------------------------------------------------*/
@include breakpoint(lg) {

}

@include breakpoint(sm) {

}

@include breakpoint(xs) {
  .course-single .bottom-bar .more {
    border-left: 0 !important;
    border-top: 1px solid rgba(0, 0, 0, .2) !important;
    float: right;
    width: 100%;
    padding: 15px;
  }
  .course-single .bottom-bar .course-name {
    padding: 8px 10px !important;
    font-size: 13px !important;
  }
  .course-single .bottom-bar .course-name span {
    font-size: 13px !important;
  }
}