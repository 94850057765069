// Font weights
$light: 300;
$regular: 400;
$bold: 700;
$ultra_bold: 900;

// Base Font
$base-font-family: 'Open Sans', sans-serif;
$base-font-weight: $regular;
$base-font-size: 16px;
$base-line-height: 1.6;

//base colors
$color-primary: #0083CA;
$color-text: #171717; 
