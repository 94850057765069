/*--------------------------------------------------------------
# Style
--------------------------------------------------------------*/
.small_form {
	width: 100%;
	position: relative;
	left: 0px;
	top: 0px;
	float: left;

	.meta {
		font-size: 14px;
		margin-bottom: 20px;
		color: rgba(0, 0, 0, 0.6);
		width: 100%;
		float: left;
	}

	.form {
		width: 450px;
		float: left;
		left: 50%;
		background: #fff;
		float: left;
		margin: 50px 0px 50px -225px;
		position: relative;
		padding: 40px 40px 10px 40px;
		border-radius: 3px;
		box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);

		.logo {
			width: 100%;
			text-align: center;
			height: auto;
			padding-bottom: 25px;
			border-bottom: 1px solid #eee;
			margin-bottom: 15px;

			img {
				max-width: 150px;
			}
		}

		h3 {
			font-weight: bold;
			margin: 0px 0px 5px 0px;
			font-size: 26px;

			span {
				color: $color-primary;
			}
		}

		.um .um-form {

			p, div {
				text-align: left !important;
			}

			.um-col-alt-b {
				padding-top: 5px;
			}

			.um-field {
				padding-top: 10px;
				margin-top: 0px;
			}

			.um-left, .um-right {
				width: 100%;
				float: left;
			}

			.um-right {
				margin-top: 10px;
			}

			.um-button, a.um-button {
				color: #fff;
				border: 0px !important;
				box-shadow: 0px 0px 0px !important;
				width: 100%;
				float: left;

				&.um-alt {
					padding: 5px 0px !important;
					background: none;
					box-shadow: 0px 0px 0px !important;
					color: $color-text;
					text-decoration: underline !important;
				}
			}
			
			.um-field-label {
				margin-bottom: 0px;

				label {
					font-size: 13px !important;
					margin-bottom: 0px;
				}
			}

			input {
				border: 1px solid #cdcdcd !important;
				width: 100%;
				box-sizing: border-box;
				padding: 8px 10px;
				border-radius: 2px;
				box-shadow: inset 1px 2px 2px 0px rgba(0, 0, 0, 0.05) !important;

				&[type="checkbox"] {
					float: left;
					width: auto;
				}
			}
		}
	}

	&.bevestig {
		.form {
			margin-top: 0px;
		}

		.line {
			border-top: 1px solid #eee;
			font-weight: bold;
			padding: 10px 5px;
			width: 100%;
			float: left;

			.name {
				color: $color-primary;
			}
		}

		.field {
			width: 100%;
			float: left;
			padding: 5px;
			margin-bottom: 5px;

			&.company, &.versturen {
				display: none;
			}

			.label {
				font-weight: bold;
				font-size: 14px;

				sup {
					color:#f00;
				}
			}

			&.half {
				width: 50%;
			}

			input {
				width: 100%;
				float: left;
				border: 1px solid darken(#eee, 10);
				padding: 5px;
				border-radius: 3px;
				box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);

				&:focus {
					outline: none;
					border: 1px solid $color-primary;
				}

				&[type="checkbox"] {
					float: left;
					margin-right: 5px;
					width: auto;
					margin-left: 0px;
					position: relative;
				}
			}

			label.checkbox {
				font-weight: normal;
			}

			select {
				width: 100%;
				float: left;
				height: 37px;
			}
		}

		form {
			width: 100%;
			float: left;
			border-top: 1px solid #eee;
		}

		.button {
			width: 100%;
			border: 0px;
			border-radius: 3px;
			margin-bottom: 20px;
			display: block;
		}

		&.center {
			text-align: center;
		}
	}
}

/*--------------------------------------------------------------
# Responsive
--------------------------------------------------------------*/
@include breakpoint(lg) {

}

@include breakpoint(md) {

}

@include breakpoint(sm) {

}

@include breakpoint(xs) {

  .um-37.um {
    width: 250px;
  }
  .um-col-alt-b {
    padding-top: 0px;
  }
	.small_form {
		width: 100%;
		min-height: calc(100vh - 64px);
		position: relative;
		left: 0px;
		top: 0px;
		float: left;

		.form {
          width: 100%;
          float: left;
          background: #fff;
          float: right;
          left: 0px;
          margin: 10px 0;
          padding: 15px 22px 0;
          border-radius: 3px;
          box-shadow: 0 0 20px rgba(0,0,0,.2);


          .um-password {
          	.um-button {
          		margin-bottom: 20px;
          	}	
          }

          form, .um-form, .um-login {
          	width: 100%;
          	float: left;
          	max-width: 1000px;
          }


			h3 {
				font-weight: bold;
				margin: 0px 0px 5px 0px;
				font-size: 26px;

				span {
					color: $color-primary;
				}
			}

			.um .um-form {

				.um-field {
					padding-top: 10px;
					margin-top: 0px;
				}

				.um-left, .um-right {
					width: 100%;
					float: left;
				}

				.um-right {
					margin-top: 10px;
				}

				.um-button {
					color: #fff;
					border: 0px !important;
					box-shadow: 0px;
					width: 100%;
					float: left;

					&.um-alt {
						padding: 5px 0px !important;
						background: none;
						color: $color-text;
						text-decoration: underline !important;
					}
				}

				.um-field-label {
					margin-bottom: 0px;

					label {
						font-size: 13px !important;
						margin-bottom: 0px;
					}
				}

				input {
					border: 1px solid #cdcdcd !important;
					width: 100%;
					box-sizing: border-box;
					padding: 8px 10px;
					border-radius: 2px;
					box-shadow: inset 1px 2px 2px 0px rgba(0, 0, 0, 0.05) !important;
				}
			}
		}
	}

	.um-col-alt-b {
		padding-top: 0px !important;
	}
	#menu-head-menu-offline {
		width: 100%;
		text-align: center;
	}
}