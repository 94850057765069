/*--------------------------------------------------------------
# Responsive mixins
--------------------------------------------------------------*/

@mixin breakpoint($class) {
    @if $class==xs {
        @media (max-width: 543px) {
            @content;
        }
    }
    @else if $class==sm {
        @media (max-width: 767px) {
            @content;
        }
    }
    @else if $class==md {
        @media (max-width: 991px) {
            @content;
        }
    }
    @else if $class==lg {
        @media (max-width: 1199px) {
            @content;
        }
    }
    @else {
        @warn "Breakpoint mixin supports: xs, sm, md, lg";
    }
}
