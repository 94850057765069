/*--------------------------------------------------------------
# Style
--------------------------------------------------------------*/
.home.page-template {
	padding-top: 0px;

	.top-bar {
		background: none;
		text-shadow: 0px 2px 3px rgba(0, 0, 0, 0.4);
	}
}
.hero_home {
	width: 100%;
	float: left;
	text-align: center;
	padding: 125px 0px 0px 0px;
	background: url(../images/homepage.jpg) no-repeat;
	background-size: cover;
	text-shadow: 0px 2px 3px rgba(0, 0, 0, 0.3);

	h1 {
		font-weight: 200;
		color: #fff;
		font-size: 40px;
		line-height: 40px;
		margin: 0px 0px 20px 0px;

		span {
			font-weight: bold;
			color: $color-primary;
		}
	}

	p {
		color: #fff;
		font-size: 18px;
		font-weight: 300;
	}

	.mockup {
		width: 700px;
		height: 440px;
		float: left;
		position: relative;
		left: 50%;
		margin-left: -350px;
		background: url(../images/mockup.png) no-repeat;
		background-size: 100% auto;
		margin-bottom: -150px;
	}
}

.approved_by {
	background: #fff;
	padding: 175px 0px 100px 0px;
	width: 100%;
	float: left;
	text-align: center;

	h3 {
		font-weight: bold;
		text-align: center;
		margin-bottom: 20px;

		span {
			font-weight: 200;
		}
	}

	img {
		max-height: 45px;
		margin: 0px 20px;
	}
}

/*--------------------------------------------------------------
# Responsive
--------------------------------------------------------------*/
@media (max-width: 700px) {

}


@include  breakpoint(xs) {

}

